import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import './SideList.css';
import { useCallback, useState } from 'react';

function SideListNode({item, items, selectClick, showProperty}) {
  const [expanded, setExpanded] = useState(false);
  const children = items.filter(i => i.parent === item.id && !i.archived);

  const expand = useCallback((e) => {
    e.stopPropagation();
    setExpanded((expanded) => !expanded);
  }, [setExpanded]);

  return <>
    <li
      key={item.id}
      className={item.archived ? "archived" : ""}
      onClick={() => { selectClick(item); if (!expanded) { setExpanded(true); }}}>
        { item.starred && <span className='icon'><FontAwesomeIcon icon={faStar}/></span> }
        {item[showProperty]}
        { children.length > 0 && <span className={expanded ? 'collapse' : 'expand'} onClick={expand}><FontAwesomeIcon icon={expanded ? faCaretDown : faCaretLeft}/></span> }
    </li>
    { expanded && children.length > 0 &&
      <ul className="children">
        { children.map(item => <SideListNode key={item.id} items={items} item={item} selectClick={selectClick} showProperty={showProperty} />) }
      </ul>
    }
    </>;
}

export default SideListNode;